@import "../../../style-constants/colors";

.landing .landing {
  &-contact {
    padding: 40px;
    background: #F8F8F8;;
    width: calc(100% - 30px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    margin: 0 15px 80px;

    &-title {
      font-size: 36px;
      line-height: 42px;
      color: $main;
      margin: 0 0 8px;
      width: 100%;
      text-align: center;
      font-weight: normal;
      font-family: 'Roboto Regular';
    }

    &-subtitle {
      font-size: 18px;
      line-height: 21px;
      color: $main;
      margin: 0 0 80px;
      width: 100%;
      text-align: center;
      font-weight: normal;
      font-family: 'Roboto Regular';
    }

    h4 {
      font-size: 24px;
      line-height: 28px;
      color: $main;
      font-weight: normal;
      margin-bottom: 27px;
      font-family: 'Roboto Regular';
    }

    a {
      font-size: 18px;
      line-height: 21px;
      color: $main;
      font-family: 'Roboto Regular';

      &.email {
        margin-bottom: 30px;
      }

      &.phone {
        margin-bottom: 8px;
      }
    }

    &-socials {
      img {
        width: 45px;
        height: 45px;
      }
    }

    &-form {
      max-width: 450px;

      .easy {
        border-bottom: 2px solid $main;
        background: transparent;
        height: 42px;
        margin: 0 0 20px;
        color: $main;
        width: 100%;
        font-size: 18px;
        line-height: 21px;
        transition: all 0.3s;

        &:hover, &:focus {
          border-color: $secondary;
        }

        &.error {
          border-bottom: 2px solid red;
        }

        &::placeholder {
          color: rgba(48, 54, 65, 0.73);
        }
      }

      button {
        border: 1px solid $main !important;
        border-radius: 0;
        width: 100%;
        margin: 20px 0;
        color: white;
        background-color: $main !important;

        &:hover {
          background-color: white !important;
          color: $main !important;
        }
      }
    }
  }
}
