@import "../../../style-constants/colors";

@media screen and (max-width: 768px) {
  .landing .landing {
    &-contact {
      padding: 20px 20px 0;

      &-links {
        display: none;
      }

      &-subtitle {
        margin-bottom: 24px;
      }

      &-form {
        max-width: 450px;
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 465px) {
  .landing .landing {
    &-contact {
      &-title {
        font-size: 28px;
        line-height: 30px;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .landing .landing {
    &-contact {
      &-title {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}
