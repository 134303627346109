@import "../../../style-constants/colors";

.landing {
  &-advantages {
    &-item {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;

      img {
        width: 80px;
        height: 80px;
      }

      h3 {
        font-size: 18px;
        line-height: 21px;
        color: $main;
        font-weight: bold;
        margin-top: 12px;
      }
    }

    &-slider {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      padding-bottom: 20px;
      background: $grey;
      height: 490px;
      position: relative;
      background: 0 0 no-repeat url("/assets/laptop.png");
      background-size: 100% 100%;

      &-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 90px;
      }

      .slick-dots.slick-dots-bottom {
        left: 0;
        opacity: 0;
        visibility: hidden;
      }

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
        }
      }

      &-list {
        border-left: 1px solid $main;
        padding-left: 20px;
        width: 50%;

        p {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px;
        }

        &-img {
          width: 105px;
          height: 105px;
          margin-right: 60px;
        }
      }

      &-cell {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        padding: 60px 40px 30px;
        height: 100%;

        &-img {
          display: none !important;
        }

        h3 {
          font-size: 36px;
          line-height: 42px;
          text-align: center;
          margin-bottom: 30px;
          color: $main;
        }
      }
    }
  }
}
