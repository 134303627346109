@import "../../../style-constants/colors";

@media screen and (min-width: 1440px) {
  .landing {
    footer {
      padding: 20px calc((100vw - 1440px) / 2 + 20px) 40px;
    }
  }
}

@media screen and (max-width: 465px) {
  .landing {
    footer {
      flex-flow: row wrap;
      h2 {
        font-size: 36px;
        line-height: 42px;
      }
    }
  }
}
