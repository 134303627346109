@import "../../../style-constants/colors";

.docs {
  &-hero {
    width: 100%;
    position: relative;
    padding: 0 10%;
    height: calc(100vw / 1.58);
    max-height: 754px;
    background-size: 1200px auto;

    h1 {
      font-size: 40px;
      line-height: 46px;
      margin-top: 15px;
      margin-bottom: 15px;
      color: #303641;
    }

    &.menu {
      background: center 40px #f8f8f9 no-repeat url('/assets/docs/docs-menu_bg.png');
    }

    &.store {
      background: center 40px #f8f8f9 no-repeat url('/assets/docs/docs-store_bg.png');
    }

    &.financial {
      background: center 40px #f8f8f9 no-repeat url('/assets/docs/docs-financial_bg.png');
    }

    &.secure {
      background: center 40px #f8f8f9 no-repeat url('/assets/docs/docs-secure_bg.png');
    }

    &.statistic-doc {
      background: center 40px #f8f8f9 no-repeat url('/assets/docs/docs-statistic_bg.png');
    }

    p {
      font-size: 18px;
      line-height: 21px;
      color: $main;
    }

    img {
      display: none;
      width: 100%;
      max-width: 638px;
      margin: 20px 30px;
    }
  }

  &-content {
    padding: 0 10%;

    h2 {
      margin: 15px 20px 55px 40px;
    }

    p {
      margin-right: 50px;
      font-size: 21px;
      line-height: 24px;

      &:nth-child(2n) {
        margin-top: 20px;
      }
    }

    img {
      width: 50%;
      margin-bottom: 40px;
      border-radius: 4px;
      box-shadow: 0 1px 20px 4px rgba(0, 0, 0, .15);
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row nowrap;

      &.reverse {
        p {
          margin-left: 50px;
          margin-right: 0;
        }
      }
    }

    &-text {
      width: 50%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column nowrap;
      display: flex;
    }
  }
}
