@import "../../../../style-constants/colors";
@import "../../../../style-constants/fonts";

.testimonials-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: row wrap;
  width: calc(100% - 40px);
  max-width: 1400px;
  margin: 0 auto 40px;
  padding: 0 20px;
  background: #F8F8F8;

  .testimonial {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    flex: 1;
    margin: 20px;
    min-width: 200px;

    img {
      height: 122px;
      width: 122px;
    }

    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      font-family: $RobotoMedium;
      color: black;
      text-align: center;
      margin: 5px 0 14px;
    }

    p {
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      font-family: $RobotoRegular;
      color: black;
    }
  }
}

.testimonials {
  &-mobile {
    &-slider.slick-slider {
      display: none;
    }
  }
}
