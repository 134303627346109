* {
  // font-family: 'Roboto Light', serif;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../assets/fonts/Roboto-Regular.ttf")
}

@font-face {
  font-family: "Roboto Light";
  src: url("../assets/fonts/Roboto-Light.ttf")
}

@font-face {
  font-family: "Roboto Medium";
  src: url("../assets/fonts/Roboto-Medium.ttf")
}

@font-face {
  font-family: "Roboto Bold";
  src: url("../assets/fonts/Roboto-Bold.ttf")
}

@font-face {
  font-family: "Roboto Italic";
  src: url("../assets/fonts/Roboto-Italic.ttf")
}
