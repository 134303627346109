@import "../../../../style-constants/colors";
@import "../../../../style-constants/fonts";

.help {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;

  &-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
    width: 100%;
    max-width: 1440px;
    margin: 70px auto 0;
    padding: 0 20px;
  }

  h3 {
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    font-family: $RobotoRegular;
    color: $main;
    margin-bottom: 14px;

    b {
      font-family: $RobotoMedium;
      font-weight: 500;
    }
  }

  &-content {
    padding: 20px 60px 20px 20px;
    border: 1px solid $main;
    border-radius: 7px;

    p {
      font-family: $RobotoLight;
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      color: black;

      b {
        font-family: $RobotoRegular;
        font-weight: 500;
      }
    }
  }
}
