@import "../../../style-constants/colors";

@media screen and (max-width: 880px) {
  .landing {
    &-why {

      &-content {
        &-slider {
          padding: 20px 20px 50px;
        }

        .slick-dots.slick-dots-bottom {
          bottom: 10px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .landing {
    &-why {
      margin-bottom: 10px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .landing {
    &-why {
      &-desktop {
        display: none;
      }

      &-content {
        display: flex;
      }
    }
  }
}


@media screen and (max-width: 680px) {
  .landing {
    &-why {
      h2 {
        text-align: center;
      }

      &-main {
        &-wrapper {
          width: 100%;
        }
      }

      &-content {
        width: 100%;

        &-slider {
          border: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .landing {
    &-why {
      h2 {
        font-size: 30px;
        line-height: 36px;
        padding: 0 20px;
        text-align: center;
      }
    }
  }
}


@media screen and (max-width: 465px) {
  .landing {
    &-why {
      h2 {
        font-size: 24px;
        line-height: 26px;
        padding: 0;
        text-align: center;
      }
    }
  }
}
