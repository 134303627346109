@import "../../../style-constants/colors";

@media screen and (max-width: 1000px) {
  .landing {
    &-feature {
      flex-flow: column nowrap;
      width: 100%;
      height: auto;

      &-text {
        order: 2;
        width: calc(100% - 30px);
        min-height: unset;

        &-wrapper {
          width: calc(100% - 30px);
          margin: 0 auto;
          max-width: 610px;
        }

        p {
          margin: 10px 0;
        }
      }

      &-slider {
        width: 100%;
        order: 1;

        .slider-wrapper {
          background-size: 100% 500px;

          .carousel-img {
            height: 400px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .landing {
    &-feature {
      &-text {
        h2 {
          margin-top: 10px !important;
        }
      }

      &-slider {
        .slider-wrapper {
          background-size: 100% 400px;

          .carousel-img {
            height: 313px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 580px) {
  .landing {
    &-feature {
      &-slider {
        .slider-wrapper {
          background-size: 100% 400px;

          .carousel-img {
            height: 313px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 532px) {
  .landing {
    &-feature {
      &-slider {
        .slider-content {
          margin: 0 10px !important;
          width: 260px !important;
        }
      }
    }
  }
}


@media screen and (max-width: 465px) {
  .landing {
    &-feature {
      &-text {
        padding: 0 20px;

        h2 {
          text-align: center;
        }
      }

      &-slider {
        .slider-wrapper {
          padding-top: 26px;
          background-size: 100% 300px;

          .carousel-img {
            height: 234px;
          }
        }
      }
    }
  }
}
