@import "../../../style-constants/colors";

.landing {
  &-pricing {
    background: white;
    max-width: 1440px;
    padding-top: 50px;

    &-title {
      font-weight: 300;
      font-size: 48px;
      line-height: 56px;
      font-family: $RobotoRegular;
      color: $main;
      margin-bottom: 10px;
      text-align: center;

      b {
        font-family: $RobotoMedium;
        font-weight: 500;
      }
    }

    &-subtitle {
      font-weight: normal;
      font-size: 18px;
      font-family: $RobotoLight;
      line-height: 21px;
      color: $main;
      margin-bottom: 30px;
    }

    h4 {
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      padding: 0 20px;
      color: black;
      margin: 50px 0 15px;
      text-align: center;
    }

    &-content {
      max-width: 500px;

      &-header {
        background: #303641;
        border-radius: 7px;
        width: 100%;
        padding: 20px;

        p {
          font-weight: 500;
          font-size: 36px;
          line-height: 42px;
          color: white;
          width: 100%;
          text-align: center;
        }
      }

      &-price {
        background: #F8F8F8;
        border-radius: 7px;
        margin-top: 8px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-flow: row nowrap;
        width: 100%;
        padding: 12px;

        h3 {
          font-weight: 500;
          font-size: 72px;
          line-height: 72px;
          color: #000000;
          margin: 0 20px 0 0;
        }

        p {
          color: $main;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 5px;
        }
      }

      &-count {
        width: 100%;
        margin: 15px 0;

        p {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          width: 100%;
          text-align: center;
        }
      }

      &-items {
        background: #F8F8F8;
        border-radius: 7px;
        padding: 35px 18px 25px 18px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row nowrap;
        margin-bottom: 20px;

        ul {
          margin: 0;
        }

        li {
          margin-bottom: 10px;
        }

        p {
          font-weight: 300;
          font-size: 18px;
          line-height: 21px;
          color: black;
        }
      }

      button {
        background: #303641;
        padding: 20px;
        width: 50%;

        a {
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: white;
        }
      }
    }
  }
}
