@import "../../../style-constants/colors";

@media screen and (max-width: 880px) {
  .landing {
    &-pricing {
      padding-top: 0;

      &-plan {
        .price {
          font-size: 30px;
          line-height: 30px;

          &.empty {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .landing {
    &-pricing {
      margin-bottom: 80px !important;

      &-title {
        font-size: 30px;
        line-height: 36px;
        padding: 0 20px;
      }

      &-subtitle {
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .landing {
    &-pricing {
      &-plan {
        width: calc(100% - 20px);
        margin: 30px 10px 0;

        &-content {
          .price {
            font-size: 42px;
            line-height: 42px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .landing {
    &-pricing {
      &-content {
        &-items {
          flex-flow: column nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 465px) {
  .landing {
    &-pricing {
      &-title {
        font-size: 24px;
        line-height: 26px;
        padding: 0 15px;
      }

      &-subtitle {
        padding: 0 15px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}
