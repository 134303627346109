@import "../../../style-constants/colors";

.landing {
  footer {
    background: $main;
    padding: 20px 40px 40px;
    width: 100%;
    position: relative;

    h2 {
      font-size: 48px;
      line-height: 56px;
      color: #FAFAFA;
      width: 100%;
      text-align: center;
      margin: 0;
    }

    .top {
      border: 1px solid white;
      padding: 10px;
      background: transparent;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 124px;
      right: 20px;

      &-wrapper {
        order: 4;
        margin: 0 0 auto auto;
      }
    }

    .white {
      color: white;
    }

    h3 {
      font-size: 20px;
      line-height: 1.35;
      color: white;
      font-weight: 500;
      margin-bottom: 24px;
    }
  }

  .footer {
    &-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: row wrap;
    }

    &-socials {
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
}
