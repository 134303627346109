@import "../../../../style-constants/colors";

@media screen and (max-width: 1100px) {
  .tariff {
    h3 {
      font-size: 32px;
      line-height: 36px;
    }

    p {
      font-size: 21px;
      line-height: 24px;
    }

    li {
      &:before {
        left: 3px;
        top: 10px;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .tariff {
    width: calc(100% - 330px);

    &-wrapper {
      margin: 20px auto 39px;

      .example {
        margin: 0 20px;
      }
    }

    &-new {
      span {
        margin-left: 323px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .tariff {
    h3 {
      font-size: 26px;
      line-height: 28px;
    }

    &-new {
      span, h4 {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .tariff {
    width: calc(100% - 310px);

    &-wrapper {
      margin-bottom: 90px;

      .example {
        margin: 0 20px 0 0;
      }
    }

    &-new {
      bottom: -70px;
      justify-content: center;

      span {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .tariff {
    width: 100%;
    align-items: center;
    margin-bottom: 30px;

    h3 {
      text-align: center;
    }

    &-wrapper {
      flex-flow: column-reverse nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 150px;

      .example {
        margin: 0 auto;
      }
    }

    &-new {
      height: auto;
      bottom: -50px;
      transform: translateY(100%);
      padding: 12px 20px;
      flex-flow: column nowrap;
      width: 100%;
      right: 0;
    }
  }
}

@media screen and (max-width: 470px) {
  .tariff {
    &-new {
      align-items: flex-start;

      span {
        margin-bottom: 6px;
      }
    }
  }
}
