@import "../../../../style-constants/colors";

@media screen and (max-width: 1040px) {
  .testimonials-wrapper {
    .testimonial {
      width: calc(50% - 50px);
      flex: unset;
    }
  }
}

@media screen and (max-width: 750px) {
  .testimonials {
    &-wrapper {
      margin: 20px;

      .mobile-hide {
        display: none;
      }
    }

    &-mobile {
      &-slider {
        display: block !important;
        width: 100%;
        max-width: 100vw;
        margin: 0 auto;
        padding: 40px 20px 70px;
        background: #F8F8F8;
        position: relative;

        .slick-dots.slick-dots-bottom {
          bottom: 20px !important;
          left: 0;

          li {
            margin: 0 8px;

            button {
              background: #C4C4C4;
              width: 7px !important;
              height: 7px;
              border-radius: 100% !important;
            }
          }
        }

        .testimonial-slide {
          width: 100%;
          display: flex !important;
          align-items: center;
          justify-content: flex-start;
          flex-flow: column nowrap;
          margin: 0;
          flex: 1;
          min-width: 225px;

          img {
            height: 125px;
            width: 125px;
          }

          h4 {
            font-size: 18px;
            line-height: 21px;
            color: $main;
            padding-bottom: 5px;
            margin: 10px 0;
          }

          p {
            font-size: 16px;
            width: 100%;
            text-align: center;
            line-height: 18px;
            color: $main;
          }
        }
      }
    }
  }

}
