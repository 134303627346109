@import "style-constants/colors";
@import "style-constants/fonts";

.no-overflow {
  overflow: hidden !important;
}

html body {
  @media print {
    // width: 5cm;

    &.no-overflow {
      overflow: visible !important;
    }
    *,
    *:before,
    *:after,
    *:first-letter,
    p:first-line,
    div:first-line,
    blockquote:first-line,
    li:first-line {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }
    .no-print {
      display: none !important;
    }
    .layout-content {
      width: 100% !important;
      overflow: visible;
    }
    .header {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      background: $main !important;

      h2 {
        color: white !important;

        &:first-letter {
          color: white !important;
        }
      }
    }

    .storage-page-header {
      margin: 10px 0 !important;
      width: 100% !important;
    }

    .storage-page-content {
      overflow: visible;
      max-height: unset;
    }

    table {
      page-break-inside: auto;
    }
    thead th {
      height: 44px;
    }
    tbody {
      page-break-inside: auto;
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    .statistic-content {
      padding: 0;
    }

    .ant-table-thead .ant-table-cell {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ant-table-wrapper {
      margin: 0 !important;
      padding: 0 !important;
    }

    // Profit Page
    .chart-content-profit {
      height: 220px !important;
    }
    .chart-content {
      height: 120px !important;
    }
    .today {
      margin: 10px 0 !important;
      width: 100% !important;
    }
    .popular {
      padding-bottom: 12px;

      h3 {
        margin-bottom: 0;
      }
    }
    .stats-cell {
      box-shadow: none;
      background: transparent;
    }
    // Canvas from ReactChart
    .chartjs-render-monitor {
      width: 100% !important;
      height: 100% !important;
    }
    .stats-cell {
      background: transparent;
    }
    .chart-hour {
      width: 65%;
    }
    .chart-day {
      width: calc(35% - 10px);
    }
    // End Profit Page
    .page-break {
      display: block;
      page-break-before: always;
    }
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  .no-min-width {
    min-width: unset;
  }

  .supply-table {
    .ant-table table {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .business-type {
    height: 60px;
    border: 1px solid $textColor;
    margin: 0 10px 10px 0;
    display: flex;
    width: 195px;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    cursor: pointer;
    background: white;

    &.large {
      width: 240px;
    }

    &.active {
      border: 2px solid $main;
      box-shadow: 0 2px 13px 3px rgba(0, 0, 0, 0.09);

      img {
        border-right: 1px solid $main;
        opacity: 1;
      }

      p {
        color: $main;
      }
    }

    img {
      padding: 10px;
      width: 98px;
      min-width: 98px;
      opacity: 0.8;
      height: 60px;
      border-right: 1px solid $textColor;
    }

    p {
      margin: 0 5px 0 10px;
      font-size: 16px;
      color: $textColor;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .shadow {
    &-s {
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    }

    &-m {
      box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    }

    &-l {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    }
  }

  .default-card {
    background: white;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    padding: 12px;
    border-radius: 4px;
  }

  .add-button {
    width: 120px;
  }

  .default-color {
    color: $main;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .no-break {
    word-wrap: unset;
    word-break: unset;
  }

  .modal {
    h3 {
      opacity: 0.9;
    }

    h4 {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85)
    }
  }


  .help-text {
    color: rgb(217, 83, 79);
    font-size: 14px;
    margin: 0 5px 0 0;
  }

  .color-red {
    color: rgb(217, 83, 79);
  }

  .color-green {
    color: green;
  }

  .color-gray {
    color: gray;
  }

  .list-title {
    p {
      font-weight: 700;
    }
  }

  .table-icon {
    transition: 400ms;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .table-bold-last-row {
    .ant-table-tbody > tr:last-child {
      td {
        background: #fafafa;
        color: rgba(0, 0, 0, .85);
        font-weight: 500;
      }
    }
  }

  .table-header-right {
    text-align: end !important;
  }

  .table-header-center {
    text-align: center !important;
  }

  .mobile-show {
    display: none;
  }

  .bottom-margin {
    margin-bottom: 10px !important;
  }

  .right-margin {
    margin-right: 10px !important;
  }

  .no-margin {
    margin: 0;
  }

  .version-title {
    margin-bottom: 5px;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  html body {
    .mobile-block {
      display: block;
    }

    .mobile-hide {
      display: none;
    }

    .mobile-column {
      flex-direction: column !important;
    }

    .mobile-full-size {
      max-width: unset !important;
      width: 100% !important;
      margin: 0 !important;
    }

    .mobile-bottom-margin {
      margin-bottom: 10px !important;
    }

    .mobile-right-margin {
      margin-right: 10px !important;
    }

    .mobile-column-reverse {
      flex-direction: column-reverse !important;
    }

    .mobile-show {
      display: block !important;
    }

    .mobile-wrap {
      flex-wrap: wrap;
    }

    .datepicker-wrapper {
      margin-left: 0 !important;
      width: 100% !important;
      max-width: 450px;
    }

    .mobile-column-payments {
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      padding: 12px !important;
    }

    .mobile-row-payments {
      flex-direction: row-reverse !important;
      justify-content: space-between !important;
      align-items: center !important;
      width: 100% !important;
    }

    .mobile-column-subscription {
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      padding: 12px !important;
    }

    .mobile-row-subscription {
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: center !important;
      width: 100% !important;

      span.subscription-name {
        margin-right: auto !important;
      }
    }

    // Update version block
    .mobile-full-width {
      width: 100% !important;
    }

    // Popular item from profit page
    .mobile-popular-row {
      flex-direction: column !important
    }

    .popular {
      width: 100%;

      &-column {
        width: 100% !important;

        &.second {
          .popular-header {
            display: none;
          }
        }
      }
    }

    .business-type {
      margin: 0 0 16px !important;
      width: 100% !important;
    }
  }
}
