@import "../../../style-constants/colors";

.landing {
  &-feature {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;
    margin-bottom: 30px;

    &-text {
      width: calc(50% - 30px);
      background: white;
      border: 1px solid $main;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;
      min-height: 400px;
      margin: 10px 15px;

      &-wrapper {
        width: 410px;
        display: flex;
        align-items: center;
        flex-flow: column nowrap;
        justify-content: flex-start;
        max-width: 100%;
        overflow: scroll;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
        width: fit-content;
        padding-bottom: 5px;
        border-bottom: 2px solid $secondary;
      }

      p {
        font-size: 18px;
        line-height: 21px;
        color: $main;
        margin: 40px 0 20px;
      }

      button {
        border: 2px solid $secondary;
        padding: 10px 21px;
        border-radius: 0;
        font-size: 14px;
        line-height: 16px;
        color: $main;
        margin: 0 0 0 auto;

        &:hover {
          border-color: $secondary;
        }
      }
    }

    &-slider {
      width: 50%;
      padding-bottom: 20px;
      background: white;
      height: 100%;

      .slider-content {
        min-height: 140px;
      }

        .ant-carousel .slick-dots-bottom {
        bottom: -50px;
      }

      .slider-wrapper {
        padding-top: 34px;
        background: 0 0 no-repeat url("/assets/ipad.png");
        background-size: 100% 400px;
      }

      .carousel-img {
        width: 300px;
        height: 313px;
      }
    }
  }
}
