@import "style-constants/colors";

html body {

  .ant-checkbox + span {
    align-self: center;
  }

  .ant-tabs-tab {
    margin: 0 8px 0 !important;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first:after, .ant-table-ping-right .ant-table-cell-fix-right-last:after, .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
    box-shadow: none;
  }

  .ant-table tbody > tr .ant-table {
    margin: 0 !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $main;
    box-shadow: none;
  }

  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $main;
    box-shadow: none;
  }

  .ant-tag {
    height: 32px !important;
    line-height: 30px !important;
    font-size: 14px !important;
    margin-bottom: 8px !important;
  }

  .clients {
    .ant-tabs-nav {
      height: 50px;
    }

    .ant-tabs-tab .anticon {
      margin-right: 5px;
    }
  }

  .places-modal {
    .ant-modal-body {
      padding: 16px;
    }

    .business-type {
      margin: 0 16px 16px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .modal-signUp {
    .ant-modal-body {
      padding: 16px;
    }

    .ant-modal-confirm-content {
      margin: 0 !important;
    }

    .anticon-info-circle {
      display: none;
    }

    h4 {
      font-weight: normal;
    }
  }

  .checkbox {
    &-large {
      .ant-checkbox-inner {
        width: 25px;
        height: 25px;

        &:after {
          top: 45%;
          left: 27%;
          width: 8px;
          height: 13px;
        }
      }
    }
  }

  /* Slider */
  .ant-carousel .slick-dots li button {
    background: rgb(81, 139, 181);
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: $secondary;
  }

  /* * */

  .ant-transfer-operation {
    margin: 0 13px;
  }

  .apexcharts-tooltip-text-value {
    font-weight: 100;
  }

  .ant-spin-text {
    color: #303641;
  }

  /* Filter Dropdown */
  .ant-table-filter-dropdown {
    min-width: 116px;

    &-btns {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
    }

    &-link {
      &.confirm {
        order: 2;
      }

      &.reset {
        order: 1;
      }
    }
  }

  /* * */

  .add-job {
    .ant-checkbox-inner {
      width: 26px;
      height: 26px;

      &:after {
        top: 48%;
        left: 30%;
        width: 7px;
        height: 13px;
      }
    }
  }


  .ant-table-expanded-row {
    .ant-table-wrapper {
      // margin: 17px 0 0 !important;
    }
  }

  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 10px 12px;
  }

  .ant-table-cell {
    background: white;
  }

  tr.ant-table-row {
    background: white;
  }

  .ant-table-header {
    background: transparent;
  }

  .ant-table-bordered .ant-table-header > table {
    border: 0;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background: #fafafa !important;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    border: 0 !important;

    .ant-table-column-has-actions.ant-table-column-has-sorters:hover {
      background: #fafafa;
    }
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: #fafafa;
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open {
    background: #fafafa;
  }

  .ant-table-placeholder {
    border-top: 0;
  }

  .ant-table-footer {
    padding: 12px;
  }

  .ant-table-bordered {
    .ant-table-tbody > tr > td {
      border-right: 0;

      /*&:first-child {
        border-left: 1px solid #e8e8e8;
      }*/

      &:last-child {
        border-right: 1px solid #e8e8e8;
      }
    }

    .ant-table-body > table {
      border: 0;
    }

    .ant-table-thead > tr > th {
      border-right: 0;
      border-bottom: 0;
    }
  }

  .ant-list-empty-text {
    padding: 0;
  }

  .ant-modal-body {
    padding: 12px;
    background: $background;
  }

  /** Spin **/
  .ant-spin-dot-item {
    background-color: $main;
  }

  /***/


  /** Tree Select **/
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: $main;
    border-color: $main;
  }

  .ant-select-tree li span.ant-select-tree-switcher {
    width: 6px;
  }

  /***/

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: $main;
  }

  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color: $main;
    box-shadow: none;
  }

  .ant-input-disabled {
    background-color: $background !important;
  }

  .ant-input:focus {
    border-color: $main;
    box-shadow: none;
  }

  .ant-btn:active, .ant-btn.active {
    color: $main;
    border-color: $main;
    background: white;
  }

  .ant-table-placeholder td {
    border-left: 0 !important;
  }

  .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
    background-color: $main;
    border-color: $main;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $main;
    background: $backgroundInverse;
  }

  .ant-tag-checkable-checked {
    background-color: $main;
    border-color: $main;
  }

  .ant-tabs-ink-bar {
    background-color: $main;
  }

  .ant-tag-checkable {
    border-color: #d9d9d9;
  }

  .ant-btn-link:not(.mobile) {
    color: $main;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $main;
    background-color: $main;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $main;
    border-color: $main;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $main !important;
  }

  .ant-btn-primary {
    border-color: $main;
    background-color: $main;
  }

  .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.7);
  }

  .ant-dropdown-menu-item {
    color: $main;
  }

  @media screen and (max-height: 900px) {
    .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
      padding: 9px 11px;
    }
  }

  @media screen and (max-width: 768px) {
    .places-modal {
      .business-type {
        margin: 0 0 16px;
        width: 100%;
      }
    }
  }
}

