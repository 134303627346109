@import "../../../style-constants/colors";

.landing {
  &-why {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 30px;

    &-main {
      position: relative;

      &-wrapper {
        width: 100%;
        padding: 0 16px;
        background: white;
      }

      h2 {
        margin: 0 0 55px !important;
        text-align: center;
      }
    }

    &-desktop {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-flow: row nowrap;
      width: 100%;

      div.testimonial {
        width: 25%;
        flex: unset;
        min-width: unset;
      }
    }

    &-content {
      margin: 15px 0 0;
      width: 100%;
      display: none;

      .ant-carousel {
        width: 100%;
      }

      &-slider {
        width: 100%;
        margin: 0 auto;
        padding: 40px 40px 70px;
        background: white;
        position: relative;
      }

      .slick-dots.slick-dots-bottom {
        bottom: 30px !important;
        left: 0;

        li {
          margin: 0 8px;

          button {
            background: #C4C4C4;
            width: 7px !important;
            height: 7px;
            border-radius: 100% !important;
          }
        }
      }
    }

    .testimonial {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      flex-flow: column nowrap;
      margin: 0;
      flex: 1;
      min-width: 225px;

      img {
        height: 100px;
      }

      h3 {
        font-size: 18px;
        line-height: 21px;
        color: $main;
        padding-bottom: 5px;
        margin: 25px 0 15px;
        border-bottom: 2px solid $secondary;
      }

      p {
        padding-top: 15px;
        font-size: 16px;
        width: 100%;
        line-height: 18px;
        color: $main;
      }
    }
  }
}
