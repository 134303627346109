@import "../../../style-constants/colors";

@media screen and (max-width: 1000px) {
  .landing {
    &-advantages {
      &-item {
        width: 20%;
      }

      height: calc(100vw * 0.67);
      max-height: calc(1440px * 0.67);
      background-size: 100% auto;
    }
  }
}

@media screen and (max-width: 880px) {
  .landing {
    &-advantages {
      display: none;
      background: none;

      &-header {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .landing {
    &-advantages {
      &-slider {
        &-list {
          width: 70%;

          &-img {
            margin-right: 20px;
          }
        }

        &-cell {
          padding: 60px 0 20px;

          h3 {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .landing {
    &-advantages {
      &-item {
        flex: 1;

        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .landing {
    &-advantages {
      &-slider {
        background-image: unset;
        height: auto;
        margin: 0 auto 20px;

        &-wrapper {
          padding: 0;
        }

        .slick-dots.slick-dots-bottom {
          bottom: -25px;
          opacity: 1;
          visibility: visible;

          li {
            margin: 0 8px;

            button {
              background: #C4C4C4;
              width: 7px !important;
              height: 7px;
              border-radius: 100% !important;
            }
          }
        }

        &-list {
          width: 90%;
          border-left: 0;
          padding-left: 0;

          &-wrapper {
            height: auto !important;
          }

          &-img {
            display: none !important;
          }

          p {
            text-align: center;
          }
        }

        .arrow {
          display: none;
        }

        &-cell {
          padding: 0;

          h3 {
            margin: 20px 0;
            font-size: 22px;
            line-height: 24px;
          }

          p {
            font-size: 16px;
            line-height: 18px;
          }

          &-img {
            width: 105px;
            height: 105px;
            display: block !important;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
