@import "../../../../style-constants/colors";
@import "../../../../style-constants/fonts";

.tariff {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-flow: column nowrap;
  margin-left: auto;
  width: calc(100% - 392px);
  margin-bottom: 80px;
  z-index: 2;

  &-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row nowrap;
    width: 100%;
    max-width: 1440px;
    margin: 70px auto 39px;
    padding: 0 20px;
  }

  &-content {
    width: 100%;
  }

  h3 {
    text-align: right;
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    font-family: $RobotoRegular;
    color: $main;
    margin-bottom: 14px;

    b {
      font-family: $RobotoMedium;
      font-weight: 500;
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }

  li {
    list-style-type: none;
    position: relative;
    padding-left: 20px;

    &:before {
      position: absolute;
      width: 6px;
      height: 6px;
      background: $main;
      border-radius: 100%;
      left: 5px;
      top: 12px;
      content: ''
    }
  }

  &-new {
    position: absolute;
    z-index: 1;
    bottom: 39px;
    right: 20px;
    width: calc(100% - 40px);
    height: 49px;
    background: #DFF1FE;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;

    span {
      font-family: $RobotoBold;
      font-size: 24px;
      line-height: 28px;
      color: #0094FF;
      margin-left: 392px;
      margin-right: 19px;
    }

    h4 {
      margin: 0;
      font-size: 24px;
      line-height: 28px;
      font-family: $RobotoRegular;
      color: black;
    }
  }
}
