$main: #303641;
$blue: rgba(124, 187, 233, 0.2);
$grey: rgba(184, 211, 231, 0.1);
$secondary: #7CBBE9;
$background: rgba(184, 211, 231, 0.2);
$backgroundInverse: white;
$red: #FF1844;

$primary: #304FFE;
$active: #0026CA;
$grey1: #454D59;
$grey3: #B0BAC9;

$lightBlue: #45a4db;
$textColor: #7f8e99;

// Fonts

$RobotoRegular: 'Roboto Regular', serif;
$RobotoLight: 'Roboto Light', serif;
$RobotoMedium: 'Roboto Medium', serif;
$RobotoBold: 'Roboto Bold', serif;
$RobotoItalic: 'Roboto Italic', serif;
