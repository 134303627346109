@import "../../../../style-constants/colors";

@media screen and (max-width: 1180px) {
  .help {
    h3 {
      font-size: 32px;
      line-height: 36px;
    }

    p {
      font-size: 21px;
      line-height: 24px;
    }

    &-content {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1040px) {
  .help {
    &-wrapper {
      margin: 40px auto;
      align-items: center;

      img {
        width: 300px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .help {
    h3 {
      font-size: 26px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 905px) {
  .sales-page {
    #pricing {
      order: 10;
    }
  }
  .help {
    &-wrapper {
      order: 9;
      margin-bottom: 0;

      img {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 605px) {
  .help {
    &-wrapper {
      order: 9;
    }
  }
}
