@import "../../../style-constants/colors";

@media screen and (max-width: 1080px) {
  .landing {
    .docs {
      &-hero {
        background-size: 100% auto;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .landing {
    .docs {
      &-hero {
        h2 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &-content {
        padding: 0 40px;

        h2 {
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .landing {
    .docs {
      &-hero {
        height: auto;
        max-height: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: column nowrap;
        background-image: none;

        img {
          display: block;
        }
      }

      &-content {
        padding: 0 10%;

        h2 {
          text-align: center;
          margin: 30px 0 20px;
        }

        &-text {
          width: 100%;
        }

        p {
          margin: 0;

          &:nth-child(2n) {
            margin: 10px 0 0;
          }
        }

        img {
          margin-top: 26px;
          width: 100%;
        }

        &-wrapper {
          flex-flow: column nowrap;
          align-items: center;
          justify-content: flex-start;

          &.reverse {
            flex-flow: column-reverse nowrap;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 465px) {
  .landing {
    .docs {
      &-hero {
        padding: 0 20px;

        p {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
