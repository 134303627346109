@import "../../../style-constants/colors";

.loyalty {
  &-exclude {
    .create-product-tag {
      background-color: white;
      color: $main;

      &.ant-tag-checkable-checked {
        background-color: $main;
        color: white
      }
    }
  }

  &-wrapper {
    max-width: 600px;
  }

  &-description {
    margin-top: 10px;
  }

  &-input {
    width: 100%;
    max-width: 300px;
  }

  &-ranges {
    width: 100%;
  }

  &-item {

  }

  &-condition {
    position: relative;
    width: 100%;

    &-input {
      margin: 0 0 0 20px;
      min-width: 250px;
    }

    &-button {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .loyalty {
    &-condition {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      width: 100% !important;

      &-input {
        width: 100% !important;
        max-width: 300px;
        margin: 0 0 10px 0 !important;
      }

      b {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &-button {
        position: absolute;
        right: -10px;
        top: 0;
        margin: 0;
      }
    }
  }
}
